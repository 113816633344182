import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
import { Model } from 'vue-api-query'
import axios from "axios";

// Vue 3rd party plugins
import '@/core/plugins/fonts';
import '@/core/plugins/unicons';
import '@/core/components/custom';
import '@/core/components/style';
import '@/core/plugins/ant-design';

import i18next from "i18next";
import I18NextVue from "i18next-vue";
import HttpApi from 'i18next-http-backend';

app.config.productionTip = false;

i18next.use(HttpApi).init({
    debug: true,
    fallbackLng: 'en',
    backend: {
        loadPath: `${process.env.VUE_APP_API_ENDPOINT}/lang/translate/{{lng}}`,
        crossDomain: true,
        withCredentials: false,
        overrideMimeType: false,
        requestOptions: {
            mode: 'cors',
            cache: 'default'
        },
    }
})

app
        .use(I18NextVue, {i18next})
    .use(store)
    .use(router)
    .mount('#app')

Model.$http = axios;
// app.config.productionTip = false;



