import PageRoutes from './PageRoutes';

const routes = [
    {
        path: '',
        name: 'blank-page',
        component: () => import('@/view/pages/BlankPage.vue'),
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/view/pages/Users.vue'),
    },
    {
        path: '/users/:id',
        name: 'users-edit',
        component: () => import('@/view/pages/users/Edit.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/view/pages/settings/Settings.vue'),
    },
    {
        path: '/translates',
        name: 'translates',
        component: () => import('@/view/pages/translates/Translates.vue'),
    },
    {
        path: '/translates/:slug',
        name: 'translates-edit',
        component: () => import('@/view/pages/translates/Edit.vue'),
    },
    ...PageRoutes,
];

export default routes;
